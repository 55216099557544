// Node
interface Node {
    cloneChildren(deep: boolean): Node[];
    getAttributes(attributes: NamedNodeMap): any;
    appendChildren(children: Node[]): void;
    replaceSelf(otherNode: Node): void;
    replaceSelfMany(otherNodes: Node[]): void;
    nodeHash(): number;
    nodeSignature(): string;
    remove(): void;
    toHtml(): string;
}

Node.prototype.cloneChildren = function (deep: boolean): Node[] {
    var result: Node[] = [];
    this.childNodes.forEach(n => result.push(n.cloneNode(deep)));
    return result;
}

Node.prototype.appendChildren = function (children: Node[]): void {
    children.forEach(child => this.appendChild(child));
}

Node.prototype.replaceSelf = function (otherNode: Node): void {
    this.parentNode?.replaceChild(otherNode, this);
}

Node.prototype.replaceSelfMany = function (otherNodes: Node[]): void {
    otherNodes.forEach(node => this.parentNode?.insertBefore(node, this));
    this.parentNode?.removeChild(this);
}

Node.prototype.nodeSignature = function (): string {
    switch (this.nodeType) {
        case Node.ELEMENT_NODE:
            return `${this.nodeType}:${this.nodeName}:${Array.from((<Element>this).attributes).map(a => ' ' + a.nodeSignature())}`;
        case Node.ATTRIBUTE_NODE:
            return `${this.nodeType}:${(<Attr>this).name}=${(<Attr>this).value}`;
        case Node.TEXT_NODE:
            return `${this.nodeType}:${this.textContent}`;
        case Node.COMMENT_NODE:
            return `${this.nodeType}:${this.nodeValue}`;
    }
    return '';
}

Node.prototype.nodeHash = function (): number {
    return this.selectNodes('@* | text() | comment()')
        .map(attr => attr.nodeValue || '')
        .reduce((a, c) => a = a + c, '')
        .hashCode();
}

Node.prototype.remove = function (): void {
    this.parentNode?.removeChild(this);
}

Node.prototype.toHtml = function (): string {
    return (<any>this).outerHTML;
}

// DOMRect
interface DOMRect {
    add(b: DOMRect): DOMRect;
    all(func: EC.Blazor.System.Predicate<number>): boolean;
    assignToElement(elm: HTMLElement): void;
    applyFunc(func: EC.Blazor.System.Func<number>): DOMRect
    apply(func: EC.Blazor.System.Func<DOMRect>): DOMRect
    blend(b: DOMRect, amount: number): DOMRect;
    equals(other: DOMRect): boolean;
    multiply(amount: number): DOMRect;
    subtract(b: DOMRect): DOMRect;
}

DOMRect.prototype.equals = function (other: DOMRect): boolean {
    return this.x == other.x
        && this.y == other.y
        && this.width == other.width
        && this.height == other.height;
}

DOMRect.prototype.add = function (b: DOMRect) {
    return new DOMRect(this.x + b.x, this.y + b.y, this.width + b.width, this.height + b.height);
}

DOMRect.prototype.assignToElement = function (elm: HTMLElement): void {
    //var rounded = this.applyFunc(n => Math.round(n));
    elm.style.left = this.x + 'px';
    elm.style.top = this.y + 'px';
    elm.style.width = this.width + 'px';
    elm.style.height = this.height + 'px';
}

DOMRect.prototype.applyFunc = function (func: EC.Blazor.System.Func<number>): DOMRect {
    return new DOMRect(
        func(this.x),
        func(this.y),
        func(this.width),
        func(this.height)
    );
}

DOMRect.prototype.blend = function (b: DOMRect, amount: number): DOMRect {
    let leftAmount = 1 - amount;
    return new DOMRect(
        (this.x * amount) + (b.x * leftAmount),
        (this.y * amount) + (b.y * leftAmount),
        (this.width * amount) + (b.width * leftAmount),
        (this.height * amount) + (b.height * leftAmount)
    )
}

DOMRect.prototype.multiply = function (amount: number): DOMRect {
    return new DOMRect(this.x * amount, this.y * amount, this.width * amount, this.height * amount)
}

DOMRect.prototype.subtract = function (b: DOMRect) {
    return new DOMRect(this.x - b.x, this.y - b.y, this.width - b.width, this.height - b.height);
}

DOMRect.prototype.all = function (func: EC.Blazor.System.Predicate<number>): boolean {
    return func(this.x)
        && func(this.y)
        && func(this.width)
        && func(this.height);
}

// String
interface String {
    toInt(): number;
    hashCode(): number;
}

String.prototype.toInt = function () {
    return parseInt(this);
}

String.prototype.hashCode = function (): number {
    var hash = 5381, i = this.length;

    while (i) {
        hash = (hash * 33) ^ this.charCodeAt(--i);
    }

    /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
    * integers. Since we want the results to be always positive, convert the
    * signed int to an unsigned by doing an unsigned bitshift. */
    return hash >>> 0;
}

// Element
interface Element {
    isChildOf(parent: Element): boolean;
    findAncestor(predicate: EC.Blazor.System.Predicate<Element>): Element;
}

Element.prototype.isChildOf = function (parent: Element): boolean {
    var elm = <Element>this;
    if (elm == parent) return true;
    if (elm.parentElement == null) return false;
    return elm.parentElement.isChildOf(parent);
}

Element.prototype.findAncestor = function (predicate: EC.Blazor.System.Predicate<Element>): Element {
    var elm = <Element>this;
    while ((elm = elm.parentElement) && (!predicate(elm)));
    return elm;
}

// MouseEvent
interface XYPosition {
    x: number;
    y: number;
}

interface MouseEvent {
    elementFromMousePosition(): Element;
    getRelativePosition(elm: Element): XYPosition;
}

MouseEvent.prototype.elementFromMousePosition = function (): Element {
    return document.elementFromPoint(this.clientX, this.clientY);
}

MouseEvent.prototype.getRelativePosition = function (elm: Element): XYPosition {
    let rect = elm.getBoundingClientRect();
    let x = this.clientX - rect.left; //x position within the element.
    let y = this.clientY - rect.top;  //y position within the element.
    return { x, y };
}
