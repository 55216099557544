namespace EC.Blazor.Upload {

    export interface FileEntry extends FileInfo {
        file: File;
    }

    export class FileEntryFactory {
        public static async createFileEntriesFromTarget(fileList: FileList): Promise<FileEntry[]> {
            let files = Array.from(fileList);
            return files.map(file => this.createFileEntry(file, (<any>file).webkitRelativePath));
        }

        public static async createFileEntriesFromDataTransferItems(itemList: DataTransferItemList): Promise<FileEntry[]> {
            let items = await this.getFileSystemEntries(itemList);
            let fileEntries = await Promise.all(items.map(async (item) => {
                let file = await this.getFileObject(item);
                return this.createFileEntry(file, item.fullPath.substring(1));
            }));
            return fileEntries;
        }

        private static createFileEntry(file: File, relativePath: string): FileEntry {
            let fileEntry: FileEntry = {
                file: file,
                id: this.createUUIDv4(),
                name: file.name,
                relativePath: relativePath ? relativePath : file.name,
                lastModified: new Date(file.lastModified),
                size: file.size,
                type: file.type,
                progress: 0,
                state: UploadState.Ready,
                tag: null,
                url: null,
            };
            return fileEntry;
        }

        private static async getFileObject(fileEntry: FileSystemFileEntry): Promise<File> {
            return new Promise(resolve => fileEntry.file(item => resolve(item)));
        }

        private static async getFileSystemEntries(itemList: DataTransferItemList): Promise<FileSystemFileEntry[]> {
            let result: FileSystemFileEntry[] = [];

            let entries = Array.from(itemList)
                .map(item => <FileSystemEntry>item.webkitGetAsEntry());

            while (entries.length > 0) {
                let entry = entries.shift();
                if (entry.isFile) result.push(<FileSystemFileEntry>entry);
                else if (entry.isDirectory) entries.push(...await this.readDirectoryEntries((<FileSystemDirectoryEntry>entry).createReader()));
            }
            return result;
        }

        private static async readDirectoryEntries(reader: FileSystemDirectoryReader): Promise<FileSystemEntry[]> {
            let result: FileSystemEntry[] = [];
            let readEntries = await this.readEntriesAsync(reader);
            // Keep reading until the returned length is 0
            while (readEntries.length > 0) {
                result.push(...readEntries);
                readEntries = await this.readEntriesAsync(reader);
            }
            return result;
        }

        private static async readEntriesAsync(directoryReader: FileSystemDirectoryReader): Promise<FileSystemEntry[]> {
            return new Promise((resolve, reject) => directoryReader.readEntries(resolve, reject));
        }

        static createUUIDv4() {
            return ('10000000-1000-4000-8000-100000000000').replace(/[018]/g, c =>
                //@ts-ignore
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        }
    }

    // FileSystem API interfaces
    interface FileSystemEntry {
        fullPath: string;
        isFile: boolean;
        isDirectory: boolean;
        name: string;
    }

    interface FileSystemFileEntry extends FileSystemEntry {
        file(successCallback: (file: File) => void, errorCallback?: (error: FileError) => void): void;
    }

    interface FileSystemDirectoryEntry extends FileSystemEntry {
        createReader(): FileSystemDirectoryReader;
        getDirectory(): FileSystemDirectoryEntry;
        getFile(): FileSystemFileEntry;
    }

    interface FileSystemDirectoryReader {
        readEntries(successCallback: (entries: FileSystemEntry[]) => void, errorCallback?: (error: FileError) => void): void;
    }

    interface FileError extends Error { }

}