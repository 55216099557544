namespace EC.Blazor.Upload {
    
    export type ValueAction<T> = (item: T) => void;
    export type ValueAction2<K,T> = (k:K, item: T) => void;
    export type Predicate = () => boolean;

    export enum UploadMode {
        Single = 1 << 0,
        Multiple = 1 << 1,
        Folder = 1 << 2,
        All = ~(~0 << 3),
    }

    export enum UploadState {
        Ready,
        Awaiting,
        Uploading,
        Complete,
        Cancelled,
    }

    export interface FileInfoId {
        id: string;
    }

    export interface FileInfoProgress extends FileInfoId {
        progress: number;
    }

    export interface FileInfo extends FileInfoProgress {
        name: string;
        relativePath: string;
        lastModified: Date;
        size: number;
        type: string;
        state: UploadState;
        tag: string;
        url: string;
    }

    export interface ChunkInfo {
        Offset: number;
        Size: number;
    }

    export class UploadStatus {
        readyCount: number;
        queuedCount: number;
        completedCount: number;
    }

    export class UploaderStatus {
        public static GetStatus(): UploadStatus {
            var files = Uploader._uploader._files.values();
            debugger;
            var result = new UploadStatus()
            result.readyCount = files.filter(f => f.state == UploadState.Ready || f.state == UploadState.Cancelled).length;
            result.queuedCount = files.filter(f => f.state == UploadState.Uploading || f.state == UploadState.Awaiting).length;
            result.completedCount = files.filter(f => f.state == UploadState.Complete).length;
            return result;
        }
    }

    export class Uploader {
        static _uploader = new Uploader();
        _dotnetObject: any;
        _elm: HTMLElement;
        _files: EC.Blazor.System.Dictionary<FileEntry> = new EC.Blazor.System.Dictionary<FileEntry>();
        _timeout: number;
        _inputElm: HTMLInputElement;
        _currentUploads: number = 0;

        static getInstance(elm: Element): Uploader {
            return Uploader._uploader;
        }

        public static Attach(elm: HTMLElement, dotnetObject: any) {
            var uploader = Uploader.getInstance(elm);
            uploader._dotnetObject = dotnetObject;
            uploader._elm = elm;
            uploader.addEventListeners();
            uploader.UpdateFileInfos(<FileInfo[]>uploader._files.values());
        }

        public static Detach(elm: HTMLElement, dotnetObject: any) {
            var uploader = Uploader.getInstance(elm);
            uploader._elm = null;
            uploader._dotnetObject = null;
            //uploader.addEventListeners();
        }

        public static async StartUpload(elm: HTMLElement, fileId: string, url: string) {
            let instance = Uploader.getInstance(elm);
            instance.uploadInternal(fileId, url);
        }

        public static async CancelUpload(elm: HTMLElement, fileId: string) {
            let instance = Uploader.getInstance(elm);
            instance.cancelUploadInternal(fileId);
        }

        public static async DeleteUpload(elm: HTMLElement, fileId: string) {
            let instance = Uploader.getInstance(elm);
            instance.deleteUploadInternal(fileId);
        }

        public static async ActivateFileInput(elm: HTMLElement, mode: UploadMode, url:string, tag: string) {
            this.setFileInputMode(elm, mode, url, tag);
            elm.click();
        }

        public static async UpdateFileInfo(elm: HTMLElement, info: FileInfo) {
            let instance = Uploader.getInstance(elm);
            let fileEntry = instance.getFileEntry(info.id);
            fileEntry.tag = info.tag;
            fileEntry.url = info.url;
        }

        // TODO: Hackish solution - FireFox only executes the click() method, when it is originating from a real user click event
        // So instead of callng this from blazor through jsinterop, we use onclick javascript events directly on the buttons, and let them find the instance
        // (Maybe it's better to let this class know of the button references during intialization, and let it assign the handlers)
        public static async ClickFileInput(e: MouseEvent, mode: UploadMode, url: string, tag: string) {
            let elm = (<Element>e.target).findAncestor(item => item.getAttribute('data-ec-component') == 'Uploader');
            if (elm == null) throw new Error('Could not find an ancestor FileInput element')
            var instance = this.getInstance(elm);
            var inputElm = instance._inputElm;
            this.ActivateFileInput(inputElm, mode, url, tag);
        }

        private static setFileInputMode(elm: HTMLElement, mode: UploadMode, url:string, tag: string) {
            elm.toggleAttribute('multiple', mode == UploadMode.Multiple);
            elm.toggleAttribute('directory', mode == UploadMode.Folder);
            elm.toggleAttribute('webkitdirectory', mode == UploadMode.Folder);
            elm.dataset['uploadUrl'] = url;
            elm.dataset['tag'] = tag;
        }

        private deleteUploadInternal(fileId: string) {
            let fileEntry = this.getFileEntry(fileId);
            this._files.remove(fileId);
            this.NotifyFileEntryDeleted(fileEntry);
        }

        private cancelUploadInternal(fileId: string) {
            let fileEntry = this.getFileEntry(fileId);
            this.setFileEntryState(fileEntry, UploadState.Cancelled);
            this.setFileEntryProgress(fileEntry, 0);
        }

        private async onChange(e: InputEvent) {
            let fileElement = <HTMLInputElement>e.target;
            let fileEntries = await FileEntryFactory.createFileEntriesFromTarget(fileElement.files);
            fileEntries.forEach(fe => {
                fe.tag = fileElement.dataset['tag'];
                fe.url = fileElement.dataset['uploadUrl'];
            });
            this.addFileEntries(fileEntries);
        }

        private onDragLeave(e: DragEvent) {
            e.preventDefault();
            let elm = e.elementFromMousePosition();
            if (elm.isChildOf(this._elm)) return;
            this.showDragOverEffect(false);
        }

        private onDragOver(e: DragEvent) {
            e.preventDefault();
            this.showDragOverEffect(true);
        };

        private async onPaste(e: ClipboardEvent) {
            //TODO: Not working in Chrome
            e.preventDefault();
            let fileEntries = await FileEntryFactory.createFileEntriesFromDataTransferItems(e.clipboardData.items);
            this.addFileEntries(fileEntries);
        };

        private async onDrop(e: DragEvent) {
            e.preventDefault();
            this.showDragOverEffect(false);
            let fileEntries = await FileEntryFactory.createFileEntriesFromDataTransferItems(e.dataTransfer.items);
            fileEntries.forEach(fe => {
                fe.tag = this._inputElm.dataset['tag']
                fe.url = this._inputElm.dataset['uploadUrl'];
            });
            this.addFileEntries(fileEntries);
        }

        private showDragOverEffect(show: boolean) {
            this._elm.classList.toggle('drag-over', show);
        }

        private async uploadInternal(fileId: string, url: string) {
            let fileEntry = this.getFileEntry(fileId);
            let file = fileEntry.file;

            this.setFileEntryState(fileEntry, UploadState.Awaiting);
            this.setFileEntryProgress(fileEntry, 0);

            while (fileEntry.state == UploadState.Awaiting) {
                await EC.Blazor.System.TBD.sleep(50);
                var contin = await EC.Blazor.System.TBD.waitFor(() => this._currentUploads < 2, 100);
                if (fileEntry.state != UploadState.Awaiting) return;
                if (contin) break;
            }

            this._currentUploads++;
            try {

                this.setFileEntryState(fileEntry, UploadState.Uploading);

                let fd = new FormData();
                var f = new File([file], fileEntry.relativePath);
                fd.append('uploadFile', f);
                await Uploader.sendAsync('POST', url, fd, (request, bytesSent) => this.handleProgress(request, bytesSent, fileEntry));

                this.setFileEntryProgress(fileEntry, 100);
                this.setFileEntryState(fileEntry, UploadState.Complete);
            } finally {
                this._currentUploads--;
            }
        }

        private async handleProgress(request: XMLHttpRequest, bytesSent: number, fileEntry: FileEntry) {
            this.setFileEntryProgress(fileEntry, bytesSent * 100 / fileEntry.size);
            if (fileEntry.state === UploadState.Cancelled) request.abort();
        }

        private async setFileEntryState(fileEntry: FileEntry, state: UploadState) {
            fileEntry.state = state;
            this.NotifyFileInfoChange(fileEntry);
        }

        private async setFileEntryProgress(fileEntry: FileEntry, progress: number) {
            fileEntry.progress = progress;
            this.NotifyFileInfoProgress(fileEntry);
        }

        private async NotifyFileInfoProgress(fileEntry: FileInfoProgress) {
            if (this._dotnetObject == null) return;
            await this._dotnetObject.invokeMethodAsync('NotifyFileInfoProgress', fileEntry).then(null, function (err) {
                throw new Error(err);
            });
        }

        private async NotifyFileInfoChange(fileInfo: FileInfo) {
            await this._dotnetObject.invokeMethodAsync('NotifyFileInfoChange', fileInfo).then(null, function (err) {
                throw new Error(err);
            });
        }

        private async UpdateFileInfos(fileInfos: FileInfo[]) {
            await this._dotnetObject.invokeMethodAsync('UpdateFileInfos', fileInfos).then(null, function (err) {
                throw new Error(err);
            });
        }

        private async NotifyFileEntryDeleted(fileEntry: FileInfo) {
            await this._dotnetObject.invokeMethodAsync('NotifyFileInfoDeleted', fileEntry).then(null, function (err) {
                throw new Error(err);
            });
        }

        private getFileEntry(fileId: string): FileEntry {
            return this._files.item(fileId);
        }

        private addEventListeners() {
            let elm = this._elm;
            this._inputElm = elm.querySelector('input');
            this._inputElm.addEventListener('change', (e) => this.onChange(<InputEvent>e));
            elm.addEventListener('dragleave', (e) => this.onDragLeave(e));
            elm.addEventListener('dragenter', (e) => this.onDragOver(e));
            elm.addEventListener('dragover', (e) => this.onDragOver(e));
            elm.addEventListener('drop', (e) => this.onDrop(e));
            elm.addEventListener('paste', (e) => this.onPaste(e));
        }

        private async addFileEntries(fileEntries: FileEntry[]) {
            let equals = function (a: FileEntry, b: FileEntry) {
                return a.lastModified.valueOf() == b.lastModified.valueOf()
                    && a.relativePath == b.relativePath
                    && a.size == b.size
                    && a.type == b.type;
            }
            let existingEntries = this._files.values();
            let newFileEntries = fileEntries.filter(fe => !existingEntries.find(efe => equals(efe, fe)));
            newFileEntries.forEach(file => this._files.add(file.id, file));
            await this.UpdateFileInfos(<FileInfo[]>this._files.values());
        }

        static sendAsync(method: string, url: string, data: any, sendProgress: ValueAction2<XMLHttpRequest, number> = null): Promise<XMLHttpRequest> {
            return new Promise<any>((resolve, reject) => {
                let request = new XMLHttpRequest();
                let progressHandler = null;

                if (sendProgress != null) {
                    progressHandler = (e: ProgressEvent) => sendProgress(request, e.loaded);
                    request.upload.addEventListener("progress", progressHandler);
                }

                request.onreadystatechange = function (event) {
                    if (request.readyState !== 4) return;
                    var success = request.status >= 200 && request.status < 300;
                    if (success) resolve(request);
                    else reject(request);
                }

                request.open(method, url, true);
                request.send(data);
            });
        }

        //private async chunkUploadInternal(fileId: string, url: string, chunkSize: number) {
        //    let fileEntry = this.getFileEntry(fileId);
        //    let file = fileEntry.file;
        //    let offset = 0;

        //    this.setFileEntryState(fileEntry, UploadState.Uploading);

        //    while (offset < file.size) {
        //        if (fileEntry.State != UploadState.Uploading) return;

        //        let slice = file.slice(offset, offset + chunkSize, file.type);
        //        let chunkInfo: ChunkInfo = { Offset: offset, Size: slice.size };
        //        let fileInfo: FileInfo = fileEntry;

        //        let fd = new FormData();
        //        fd.append('fileData', slice);
        //        fd.append('fileInfoJson', JSON.stringify(fileInfo));
        //        fd.append('chunkInfoJson', JSON.stringify(chunkInfo));

        //        await Uploader.sendAsync('POST', url, fd, (request, bytesSent) => this.handleProgress(request, bytesSent, fileEntry));

        //        offset += chunkSize;
        //    }

        //    this.setFileEntryProgress(fileEntry, 100);
        //    this.setFileEntryState(fileEntry, UploadState.Complete);
        //}
    }
}