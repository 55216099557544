namespace EC.Blazor.System {

    export type ValueAction<T> = (item: T) => void;
    export type Action<T> = (n: T) => void;
    export type Func<T> = (n: T) => T;
    export type Predicate<T> = (n: T) => boolean;
    export type Newable<T> = { new(...args: any[]): T; };

    export class TBD {
        static throttle(callback: Function, interval: number) {
            let isThrottled = false;

            return function (...args) {
                if (isThrottled) return;
                isThrottled = true;

                callback.apply(this, args);
                setTimeout(() => isThrottled = false, interval);
            }
        }

        // Invokes the callback after the interval period has elapsed without further calls
        static debounce(callback: Function, interval: number) {
            var timeout;
            return function (...args) {
                var context = this;
                var callbackLater = function () {
                    timeout = null;
                    callback.apply(context, args);
                };
                clearTimeout(timeout);
                timeout = setTimeout(callbackLater, interval);
            };
        };

        static createUUIDv4() {
            return ('10000000-1000-4000-8000-100000000000').replace(/[018]/g, c =>
                //@ts-ignore
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        }

        static sendAsync(method: string, url: string, data: any, sendProgress: ValueAction<number> = null): Promise<XMLHttpRequest> {
            return new Promise<any>((resolve, reject) => {
                let request = new XMLHttpRequest();

                if (sendProgress != null) request.upload.addEventListener("progress", (e: ProgressEvent) => sendProgress(e.loaded));

                request.onreadystatechange = function (event) {
                    if (request.readyState !== 4) return;
                    var success = request.status >= 200 && request.status < 300;
                    if (success) resolve(request);
                    else reject(request);
                }

                request.open(method, url, true);
                request.send(data);
            });
        }

        static sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        static async waitFor(condition, timeOut) {
            timeOut = timeOut || 2000;
            while (true) {
                if (condition()) return true;
                await this.sleep(1);
                timeOut--;
                if (timeOut == 0) {
                    console.warn('Timeout while waiting for:', condition);
                    return false;
                }
            }
        }
    }
}